
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import KTAside from '@/layout/aside/Aside.vue';
import KTNoAuthHeader from '@/views/v2/views/layout/NoAuthHeader.vue';
import KTFooter from '@/views/v2/views/layout/Footer.vue';
import HtmlClass from '@/core/services/LayoutService';
import KTScrollTop from '@/layout/extras/ScrollTop.vue';
import KTLoader from '@/components/Loader.vue';

import { Actions } from '@/store/enums/StoreEnums';
import {
  Actions as AuthActions,
  Getters as AuthGetters,
} from '@/store/enums/AuthEnums';
import {
  // toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from '@/core/helpers/config';
import router from '@/router';
import store from '@/store';

export default defineComponent({
  name: 'Layout',
  components: {
    KTAside,
    KTNoAuthHeader,
    KTFooter,
    KTScrollTop,
    //KTCreateApp,
    //KTDrawerMessenger,
    KTLoader,
    // KTMobilePageTitle,
  },
  data: () => {
    return {};
  },

  mounted() {
    setTimeout(() => {
      // Remove page loader after some time
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);
  },

  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      profilePicture: AuthGetters.GET_AUTH_USER_PROFILE_PICTURE,
      actionErrors: AuthGetters.GET_AUTH_USER_ACTION_ERROR,
    }),

    setRouteIsDashboard() {
      return router.currentRoute.value.name === 'dashboard';
    },
    pageTitle() {
      return store.getters.pageTitle;
    },
    breadcrumbs() {
      return store.getters.pageBreadcrumbPath;
    },
    isDashboard() {
      return this.$router.currentRoute.value;
    },
  },

  setup() {
    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init();

    return {
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      themeLightLogo,
      themeDarkLogo,
    };
  },
});
