
import { defineComponent } from 'vue';
import MainLogo from '@/layout/header/partials/MainLogo.vue';

export default defineComponent({
  name: 'KTNoAuthHeader',
  props: {
    currentUser: {
      type: Object,
    },
    user: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  components: {
    MainLogo,
  },
  data() {
    return {
      menu: false,
    };
  },
});
